/* FlashcardCell.css */

.flashcard-cell {
  perspective: 800px;
  margin: 0 auto;
}

.flashcard-cell-inner {
  position: relative;
  width: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.flashcard-cell.flipped .flashcard-cell-inner {
  transform: rotateY(180deg);
}

/* Ambas caras deben permitir múltiples líneas y ajuste de texto */
.flashcard-cell-front,
.flashcard-cell-back {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  display: block;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;
  text-align: left; 
  white-space: normal; /* Permite usar múltiples líneas */
  overflow-wrap: break-word; /* Ajusta las palabras largas */
  word-break: break-word;
}

.flashcard-cell-front {
  background-color: #fff;
  color: #000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.flashcard-cell-back {
  background-color: #f0f0f0;
  color: #000;
  transform: rotateY(180deg);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Ajustes en la tabla para permitir ajuste de texto */
table {
  border-collapse: collapse;
  width: 100%; /* Asegura que la tabla ocupe todo el ancho disponible */
  table-layout: fixed; /* Para que los word-wrap funcionen correctamente */
}

th, td {
  text-align: left;
  vertical-align: top;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  padding: 8px;
}

button {
  outline: none;
}